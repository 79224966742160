import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { Content } from "page_components/checkout_summary"

import CustomerProvider from "context/customer"

const Order = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Zamówienie" />
      <CustomerProvider>
        <Content />
      </CustomerProvider>
    </Layout>
  )
}

export const Head = () => <Seo title="Zamówienie" />

export default Order
